<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场中心</a-breadcrumb-item>
            <a-breadcrumb-item>Pool</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="search">
                    <a-form layout='inline' v-if="selectedRowKeys.length == 0" @submit="searchList">
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>微信昵称</span>
                        </template>
                        <a-input v-model.trim="searchParams.search.nickname" placeholder="请输入微信昵称" style="width: 140px" allowClear />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>手机号码</span>
                        </template>
                        <a-input v-model.trim="searchParams.search.cellphone" placeholder="请输入手机号码" style="width: 140px" allowClear />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-range-picker
                        style="width: 230px"
                        :ranges="rangesData"
                        v-model='searchDatas.add'
                        allowClear
                        @change="(val,time)=>handleTime(val,time,'add')" />
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
                    </a-form-item>
                    </a-form>
                    <a-form layout='inline' v-else>
                        <a-form-item>
                            已选择<span>{{selectedRowKeys.length}}</span>项
                        </a-form-item>
                        <a-form-item>
                            <a-divider type="vertical" />
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="showTransfer()" icon="apartment">批量分配</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <!-- <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
                        </a-form-item>
                    </a-form>
                </div> -->
            </div>
            
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-alert message="Pool 数据为系统注册账户，只显示不存在招生咨询中的手机号码。如号码已存在线索中则不显示。" type="info" show-icon />
                <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
                :selection="{key: 'id'}"
                :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                :columns="columns" :dataSource="list" >
                <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>
                
                <template slot="nickname" slot-scope="text,record">
                    <div style="display:flex;align-items:center">
                        <a-avatar :size="32" shape="square" icon="user" :src="record.headimgurl" />
                        <div style="margin-left:5px">
                            <a style="max-width:90px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">{{ text }}</a>
                            <div>
                                <span style="font-size:12px;color: rgba(69, 90, 100, 0.6);">
                                    ({{record.sex == 1? '男' : '女'}})                    
                                </span>
                            </div>
                        </div>
                    </div>
                </template>

                <template slot="cellphone" slot-scope="text,record">
                    <div>
                        {{record.cellphone}}
                    </div>
                    <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);" v-if="record.cellphone_geo">
                        ({{record.cellphone_geo.province}},{{record.cellphone_geo.op}})
                    </span>
                </template>

                <template slot="ip_address" slot-scope="text,record">
                    <div>
                        {{record.ip_address}}
                    </div>
                    <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);" v-if="record.ip_address_name">
                        ({{record.ip_address_name}})
                    </span>
                </template>

                <template slot="action" slot-scope="text,record">
                    <div>
                        <a href="#" key="1" @click="showTransfer(record)">分配</a>
                    </div>
                </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <transfer :item='modalData' v-if="isTransfer"/>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '微信昵称',width:'300px', dataIndex: 'nickname', key: 'nickname' ,scopedSlots: { customRender: 'nickname' }},
  { title: '手机号码',width:'300px', dataIndex: 'cellphone', key: 'cellphone',scopedSlots: { customRender: 'cellphone' }},
  { title: '地域', dataIndex: 'country', key: 'country'},
  { title: '省', dataIndex: 'province', key: 'province'},
  { title: '市', dataIndex: 'city', key: 'city'},
  { title: 'IP所在地', dataIndex: 'ip_address', key: 'ip_address',scopedSlots: { customRender: 'ip_address' }},
  { title: '注册时间', dataIndex: 'authtime', key: 'authtime'},
  { title: '操作', key: 'operation', align: 'right', width:60, scopedSlots: { customRender: 'action' }}
]
    import tableMixins from '@/common/mixins/table'
    import ranges from "@/common/mixins/ranges"
    import moment from 'moment'
    import transfer from './transfer'
    export default {
        name:"pool",
        mixins: [ tableMixins,ranges ],
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                columns,
                loading:false,
                isTransfer:false,
                exportLoading:false,
                list:[],
                searchDatas:{}
            }
        },
        components: {
            transfer,
        },
        methods: {
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('poolIndexAction',{data:this.searchParams})
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
            },
            showTransfer(item){
                if(item){
                    this.modalData = [item.id]
                }else{
                    this.modalData = this.selectedRowKeys
                }
                this.isTransfer = true
            },
            hideTransfer(num){
                if(num){
                    this.selectedRowKeys = []
                    this.getList()
                }
                this.isTransfer = false
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {
                    search:this.searchParams.search,
                    sort:this.searchParams.sort
                }
                let res = await this.$store.dispatch('poolExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `pool.${moment().format('YYYY.MM.DD')}.xls`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>